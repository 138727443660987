import React from 'react';
import { navigate } from 'gatsby-link'

function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

export default function SubscribeForm() {
  const [state, setState] = React.useState({})

  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value })
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    const form = e.target
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        ...state,
      }),
    })
      .then(() => navigate(form.getAttribute('action')))
      .catch((error) => alert(error))
  }

  return (
    <form 
      name="subscribeForm" 
      method="post"
      action="/success"
      data-netlify="true"
      data-netlify-honeypot="bot-field"
      onSubmit={handleSubmit}
      id="subscribe-form"
      className="subscribe-form">
      <input type="hidden" name="form-name" value="subscribeForm" />
      <p hidden>
        <label>
          Don’t fill this out: <input name="bot-field" />
        </label>
      </p>
      <div className="form-row" style={{maxWidth: "360px", margin: "0 auto 20px"}}>
        <label>
          <span className="screen-reader-text">Email address</span>
          <input type="email" name="email" required placeholder="Enter Email Address..." onChange={handleChange}/>
        </label>
      </div>
      <button className="button" type="submit">Subscribe</button>
    </form>
  );
}
