import React from 'react'
import styled from 'styled-components';

const Background = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  background-color: white;
  opacity: 0.7;
  display: ${props => props.menuOpen ? 'initial' : 'none' };
`;

export default function Overlay(props) {
  return (
    <Background  onClick={props.menuToggleClickHandler} menuOpen={props.menuOpen}/>
  )
}
