import React from 'react';
import styled from 'styled-components';
// import _ from 'lodash';

const Container = styled.section`
  background-color: #FAFAFA;
  display: flex;
  flex-direction: column-reverse;
  padding: 100px 40px;

  @media (min-width: 768px) {
    padding: 100px 80px;
    flex-direction: row;
  }

  h2, p, li {
    font-size: 20px;
    margin-bottom: 24px;
  }

  ul {
    padding-left: 0;
  }
`;

const Col = styled.div`
  width: 100%;
  box-sizing: border-box;

  @media (min-width: 768px) {
    width: 50%;
  }
`;

export default class Benefits extends React.Component {
  render() {
    return (
      <Container id="benefits">
        <Col>
          <h2>Access to a Group of Sed do Eiusmod </h2>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
          <ul>
            <li>Benefit of A</li>
            <li>Benefit of B</li>
            <li>Benefit of C</li>
          </ul>
        </Col>
        <Col></Col>
      </Container>
    );
  }
}
