import React from 'react'
import styled from 'styled-components';

const Burger = styled.button`
  background: none;
  color: #2456EA;
  border: none;
  padding: 8px 10px;
  position: relative;
  height: 20px;
  width: 30px;
  

  span {
    background-color: #2456EA;
    height: 2px;
    width: 30px;
    display: block;
    position: absolute;

    &:nth-child(1) {
      top: ${props => props.menuOpen ? "-1px" : "0" };
      left: ${props => props.menuOpen ? "6px" : "0"};
      transform-origin: left;
      transition: transform 0.3s ease-out;
      transform: ${props => props.menuOpen ? "rotate(45deg)" : "rotate(0)"};
    }

    &:nth-child(2) {
      top: calc(50% - 1px);
      left: 0;
      transition: opacity 0.3s ease-out;
      opacity: ${props => props.menuOpen ? "0" : "1"};
    }

    &:nth-child(3) {
      bottom: ${props => props.menuOpen ? "-2px" : "0"};
      left: ${props => props.menuOpen ? "6px" : "0"};
      transform-origin: left;
      transition: transform 0.3s ease-out;
      transform: ${props => props.menuOpen ? "rotate(-45deg)" : "rotate(0)"};
    }
  }
`;

export default function Hamburger(props) {
  return (
    <Burger onClick={props.click} menuOpen={props.menuOpen}>
      <span></span>
      <span></span>
      <span></span>
    </Burger>
  )
}
