import React from "react";
import _ from "lodash";
import PodcastSubscribe from "./PodcastSubscribe";

import styled from "styled-components";

import { htmlToReact, getPages, Link, safePrefix } from "../utils";
import logo from "../images/icons/em-logo.svg";
import Player from "../components/Player";

const Container = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #fafafa;

  article {
    width: 100%;
    max-width: 800px;
  }

  iframe {
    margin-bottom: 40px;
  }

  .block-header img {
    width: 80%;
    max-width: 300px;
    margin-bottom: 20px;
  }
`;

const CtaWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 30px;

  @media (min-width: 768px) {
    flex-direction: row;
  }

  a {
    width: 80%;
    text-align: center;

    @media (min-width: 768px) {
      max-width: 200px;
    }
  }

  a:first-child {
    margin-bottom: 20px;

    @media (min-width: 768px) {
      margin-right: 20px;
      margin-bottom: 0;
    }
  }
`;

export default class EpisodeBlock extends React.Component {
  render() {
    let display_posts = _.orderBy(
      getPages(this.props.pageContext.pages, "/podcast"),
      "frontmatter.date",
      "desc"
    );

    const fm = this.props.pageContext.frontmatter
    let recent_posts = display_posts[0];

    console.log(recent_posts);

    return (
      <Container
        id={_.get(this.props, "section.section_id")}
        className={
          "block posts-block bg-" + _.get(this.props, "section.bg") + " outer"
        }
      >
        <div className="block-header inner-medium">
          <img src={logo} alt="Executive Minds Podcast Logo" />
          {/* <h2 className="block-title">{_.get(this.props, 'section.title')}</h2> */}
          {fm.pod_subtitle && (
            <p className="block-subtitle">
              {fm.pod_subtitle}
            </p>
          )}
        </div>
        <article>
          <Player
            player={_.get(recent_posts, "frontmatter.embedType")}
            url={_.get(recent_posts, "frontmatter.embedId")}
          />
          <CtaWrapper>
            <Link
              to={safePrefix(_.get(recent_posts, "url"))}
              rel="bookmark"
              className="button"
            >
              View Episode
            </Link>
            <Link to="/podcast" rel="bookmark" className="button secondary">
              View all Episodes
            </Link>
          </CtaWrapper>
          <PodcastSubscribe />
        </article>
      </Container>
    );
  }
}
