import React from 'react';
import _ from 'lodash';
import styled from 'styled-components';
import {getPages, Link} from '../utils';
import PostCards from './PostCards';


const Wrapper = styled.section`
  background-color: #FAFAFA;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export default class PostsBlock extends React.Component {
  render() {
    let display_posts = _.orderBy(getPages(this.props.pageContext.pages, '/blog'), 'frontmatter.date', 'desc');
    let recent_posts;

    _.map(display_posts, (post) => {
      let postDate = Date.parse(_.get(post, 'frontmatter.date'));
      let currentTime = Date.now();

      if (postDate > currentTime) {
        _.remove(display_posts, post)
      }
    });

    recent_posts = display_posts.slice(0, 3);

    return (
      <Wrapper id={_.get(this.props, 'section.section_id')} className={'block posts-block bg-' + _.get(this.props, 'section.bg') + ' outer'}>
        <PostCards posts={recent_posts}/>
        <Link to="/blog" rel="bookmark" className="button">View all Posts</Link>
      </Wrapper>
    );
  }
}
