import React from 'react';

import {Link} from '../utils';

export default function Logo(props) {
  return (
    <Link to='/' onClick={props.click}>
      <svg width="186" height="100%" viewBox="0 0 186 43" fill="none" xmlns="http://www.w3.org/2000/svg" style={{maxWidth: props.maxWidth, }}>
        <path d="M45.816 0.311996H38.264L23.224 26.872L8.11999 0.311996H0.567993V43H8.69599V17.72L23.224 43L37.688 17.72V43H45.816V0.311996Z" fill={props.fill}/>
        <path d="M96.8 0.311996H88.672V29.624L64.8 0.311996H57.568V43H65.696V14.072L88.992 43H96.8V0.311996Z" fill={props.fill}/>
        <path d="M103.332 0.311996V7.992H117.22V43H125.348V7.992H139.172V0.311996H103.332Z" fill={props.fill}/>
        <path d="M145.755 0.311996V43H153.883V27.128H167.068L175.771 43H185.051L175.771 26.104C178.011 25.336 178.972 24.888 180.188 23.8C183.068 21.24 184.732 17.528 184.732 13.688C184.732 8.44 181.659 3.704 176.923 1.528C174.555 0.439999 173.723 0.311996 169.691 0.311996H145.755ZM153.883 19V8.44H170.396C172.38 8.44 172.891 8.568 173.979 9.144C175.643 10.168 176.668 11.96 176.668 13.944C176.668 16.696 174.236 19 171.292 19H153.883Z" fill={props.fill}/>
      </svg>
    </Link>
  )
}
