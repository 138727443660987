import React from 'react';
import {Helmet} from 'react-helmet';
import { Link } from 'gatsby';
import _ from 'lodash';
import styled from 'styled-components';

import Header from './Header/Header';
import Logo from './Logo';

import favicon16 from "../images/favicon.png";
import favicon32 from "../images/favicon@2x.png";
import favicon64 from "../images/favicon@3x.png";

const Container = styled.main`
  max-width: calc(100% - 20px);
  margin: 40px auto 10px;

  @media (min-width: 768px) {
    max-width: 650px;
    margin: 10vh auto;
  }

  @media (min-width: 1024px) {
    max-width: 900px;
  }

  @media (min-width: 1200px) {
    max-width: 1000px;
  }

  @media (min-width: 1400px) {
    max-width: 1200px;
  }
`;

const StyledFooter = styled.footer`
  padding 0 10px;

  span {
    margin-left: 5px;
  }
`;

const StyledLink = styled(Link)`
  margin-left: 2rem;
`

export default class Body extends React.Component {
  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title>{_.get(this.props, 'pageContext.frontmatter.title') && _.get(this.props, 'pageContext.frontmatter.title') + ' - '}{_.get(this.props, 'pageContext.site.siteMetadata.title') || 'wearemntr'}</title>
          <meta charSet="utf-8"/>
          <meta name="viewport" content="width=device-width, initialScale=1.0" />
          <meta name="google" content="notranslate" />
          <link href="https://fonts.googleapis.com/css?family=Inconsolata|Karla:400,700" rel="stylesheet"></link>
          <link href={favicon16} rel="icon" type="image/png" sizes="16x16"></link>
          <link href={favicon32} rel="icon" type="image/png" sizes="32x32"></link>
          <link href={favicon64} rel="shortcut icon" type="image/png"></link>
        </Helmet>
        <div id="page" className={'site palette-' + _.get(this.props, 'pageContext.site.siteMetadata.palette')}>
          <Header {...this.props} />
          <Container id="content" className="site-content">
            {this.props.children}
            <StyledFooter>
              <Logo fill="#2456EA" maxWidth="50px"/>
              <span>© 2019</span>
              <StyledLink to="/privacy-policy">Privacy Policy</StyledLink>
            </StyledFooter>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}
