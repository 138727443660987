import React from 'react';
import _ from 'lodash';
import {Link, safePrefix} from '../utils';
import moment from 'moment-strftime';
import styled from 'styled-components';

const BlogCard = styled(Link)`
  .post-card-thumbnail {
    display: none !important;
  }

  @media (min-width: 900px) {
    flex: 0 0 50% !important;
  }
`;

const FeaturedBlog = styled(Link)`
  flex: 0 0 100% !important;

  .post-card-inside {
    background-color: #FF6367 !important;
    color: white !important;

    a,
    p,
    h3 {
      color: white !important;
    }

    .post-title {
      font-weight: 300;

      @media (min-width: 1024px) {
        max-width: 60%;
        margin-bottom: 60px;
      }
    }
  }

  @media (min-width: 900px) {
    flex-basis: 100% !important;
  }

  .post-card-thumbnail {
    display: inherit !important;

    @media (min-width: 1024px) {
      width: 30% !important;
    }

    &:before {
      padding-top: 100%;
    }
  }
`;

const Inner = styled.div`
  align-items: stretch;
  
  .img-thumb {
    position: relative;
    height: 200px;
    background-position: center center;
    background-size: cover;
    border-radius: 3px 3px 0 0;
  }

  @media (min-width: 600px) {
    flex-direction: row !important;

    .img-thumb {
      height: auto;
      width: 30%;
      border-radius: 3px 0 0 3px;
    }
  }
`;

export default function PostCards(props) {
  return (
    <div className="inner">
      <div className="post-feed">
        {_.map(props.posts, (post, post_idx) => {
          if ((!props.index && post_idx === 0) || (props.index && props.featured === post.name)) {
            return <FeaturedBlog key={post_idx} className="post post-card" to={safePrefix(_.get(post, 'url'))}>
              <Inner className="post-card-inside">
                {_.get(post, 'frontmatter.thumb_img_path') &&
                  <div className="img-thumb" style={{backgroundImage: "url(" + safePrefix(_.get(post, 'frontmatter.thumb_img_path')) + ")"}}></div>
                }
                <div className="post-card-content">
                  <header className="post-header">
                    <p className="post-meta">Latest Post</p>
                    <h3 className="post-title">{_.get(post, 'frontmatter.title')}</h3>
                  </header>
                  <footer className="post-meta">
                    <p>Read More</p>
                  </footer>
                </div>
              </Inner>
            </FeaturedBlog>
          } else {
            console.log(_.get(post, 'frontmatter.excerpt'))
            return <BlogCard key={post_idx} className="post post-card" to={safePrefix(_.get(post, 'url'))}>
              <Inner className="post-card-inside">
                <div className="post-card-content">
                  <header className="post-header">
                    <h3 className="post-title">{_.get(post, 'frontmatter.title')}</h3>
                  </header>
                  <div className="post-excerpt">
                    <p dangerouslySetInnerHTML={{ __html: _.get(post, 'frontmatter.excerpt') }} />
                  </div>
                  <footer className="post-meta">
                    <time className="published"
                      dateTime={moment(_.get(post, 'frontmatter.date')).strftime('%Y-%m-%d %H:%M')}>{moment(_.get(post, 'frontmatter.date')).strftime('%B %d, %Y')}</time>
                  </footer>
                </div>
              </Inner>
            </BlogCard>
          }
        })}
      </div>
    </div>
  )
}
