import React from 'react';
import styled from 'styled-components';
// import _ from 'lodash';


const Container = styled.section`
  text-align: center;
  padding: 100px 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #white;
  border: 1px solid #2456EA;
  border-left: none;
  border-right: none;

  p {
    max-width: 500px;
    margin-bottom: 0;
    font-size: 14px;
  }

  h2 {
    font-weight: 400;
    font-size: 24px;
  }
`;

export default class Testimonial extends React.Component {
  render() {
    return (
      <Container id="testimonial">
        <h2>“Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.”</h2>
        <p>Maria Lopez, VP of a Company</p>
      </Container>
    );
  }
}
