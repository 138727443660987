import React from 'react';
import styled from 'styled-components';
import {StaticQuery, graphql} from 'gatsby';
import Img from 'gatsby-image';
// import _ from 'lodash';

const Container = styled.section`
  text-align: center;
  padding: 15vh 40px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #FAFAFA;

  p {
    max-width: 500px;
  }
`;

const Inner = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 40px;
`;

const ImgWrapper = styled.div`
  width: 100%;
  display: flex;
`;

export default () => (
  <StaticQuery
    query={graphql`
    {
      file(relativePath: {eq: "browser.png"}) {
        childImageSharp {
          fixed(width: 1000, height: 500) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
    
    `}
    render={ data => {
      console.log(data.file.childImageSharp)
      return (
      <Container id="hero">
        <Inner>
          <h1>Join the MNTR Network</h1>
          <p>Whether you are an aspiring or experienced entrepreneurial executive, we have the tools and resources to propel your idea, product, or career forward.</p>
          <a href="https://wearemntr.mn.co/plans/38392?bundle_token=47b43cc05a6508adf905dfd8f34140a0&utm_source=manual" target="_blank" rel="noopener noreferrer" className="button">Join Now</a>
        </Inner>
        <ImgWrapper>
          <Img 
            fixed={data.file.childImageSharp.fixed}
            objectFit="scale-down"
            objectPosition="50% 50%"
            style={{
              maxWidth: "80vw",
              maxHeight: "40vw"
            }}
          />
        </ImgWrapper>
      </Container>
      )
    }}
  />
);
