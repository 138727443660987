import React from 'react'

export default function Player(props) {
  let player = props.player;
  let embedId = props.url;

  if (player === 'libsyn') {
    if ( embedId.length > 8) {
      let idPos = embedId.indexOf('http://directory.libsyn.com/episode/index/id/');
      embedId = embedId.slice(idPos + 45, idPos + 53);
    }
    player = <iframe title="Latest Episode" style={{border: "none"}} src={`//html5-player.libsyn.com/embed/episode/id/${embedId}/height/90/theme/custom/thumbnail/no/direction/backward/render-playlist/no/custom-color/2456ea/`} height="90" width="100%" scrolling="no"></iframe>
  } else if (player === 'spreaker') {
    if ( embedId.length > 8) {
      let idPos = embedId.indexOf('v2/episodes/');
      embedId = embedId.slice(idPos + 12, idPos + 20);
    }
    player = <iframe title="Latest Episode" src={`https://widget.spreaker.com/player?episode_id=${embedId}&theme=light&playlist=false&playlist-continuous=false&autoplay=false&live-autoplay=false&chapters-image=true&episode_image_position=left&hide-logo=true&hide-likes=false&hide-comments=false&hide-sharing=false&hide-download=true&cover_image_url=https%3A%2F%2Fd3wo5wojvuv7l.cloudfront.net%2Fimages.spreaker.com%2Foriginal%2F4231308eaf1c68efedae8622595251f6.jpg&color=ff5261`} width="100%" height="200px" frameBorder="0"></iframe>
  } else {
    player = <p>Episode Coming Soon</p>
  }

  return player
}
