import React from 'react'

import styled from 'styled-components';

import apple from '../images/icons/apple.svg';
import googlePlay from '../images/icons/google-play.svg';
import rss from '../images/icons/rss-icon.svg';
import stitcher from '../images/icons/stitcher-icon.svg';
import tunein from '../images/icons/tune.svg';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${props => props.justify || 'center'};

  h3 {
    font-family: 'Inconsolata';
    font-size: 18px;
    font-weight: 300;
    margin-bottom: 0;
  }

  a {
    margin-left: 10px;

    &:hover {
      opacity: 0.7;
    }
  }
`;

export default function PodcastSubscribe(props) {
  return (
    <Container justify={props.justify}>
      <h3>Subscribe:</h3>
      <a href="https://itunes.apple.com/us/podcast/podcast-launch-youniversity/id1158928053?mt=2" target="_blank" rel="noopener noreferrer"><img src={apple} alt=""/></a>
      <a href="https://play.google.com/music/m/Ilamv53c7wr34q6opukpbu6m6be?t%3DThe_Launch_Youniversity_Podcast_-_Launch_Youniversity" target="_blank" rel="noopener noreferrer"><img src={googlePlay} alt=""/></a>
      <a href="http://feeds.feedburner.com/launchyouniversitypodcast" target="_blank" rel="noopener noreferrer"><img src={rss} alt=""/></a>
      <a href="http://www.stitcher.com/podcast/launch-youniversity/the-launch-youniversity-podcast" target="_blank" rel="noopener noreferrer"><img src={stitcher} alt=""/></a>
      <a href="https://tunein.com/radio/The-Launch-Youniversity-Podcast-p920376/" target="_blank" rel="noopener noreferrer"><img src={tunein} alt=""/></a>
    </Container>
  )
}
