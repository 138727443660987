import React from 'react'
import styled from 'styled-components';
import {Link} from '../../utils';
import { useStaticQuery, graphql } from "gatsby";

import Logo from '../Logo';
// import SocialIcons from '../SocialIcons';

const Container = styled.div`
  background-color: #FF6367;
  box-sizing: border-box;
  padding: 40px 0 40px 40px;
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 70%;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: transform 0.3s ease-out;
  transform: ${props => props.menuOpen ? 'translateX(0)' : 'translateX(101%)'};

  @media (min-width: 375px) {
    justify-content: start;
  }

  @media (min-width: 1024px) {
    padding: 100px 0 100px 100px; 
  }

  a {
    color: white;
  }
`;

const LogoWrapper = styled.div`
  max-width: 60%;
  display: flex;

  svg {
    width: 100%;
  }

  @media (min-width: 375px) {
    margin-bottom: 30px;
  }
`;

const PageLinks = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: 375px) {
    margin-bottom: 30px;
  }

  a {
    margin-bottom: 10px;
  }
`;

export default function SideBar(props) {
  const data = useStaticQuery(graphql`
    {
      siteData {
        data {
          navigation {
            slideout_menu {
              text
              url
            }
          }
        }
      }
    }
  `);
  const links = data.siteData.data.navigation.slideout_menu

  return (
    <Container menuOpen={props.menuOpen}>
      <LogoWrapper>
        <Logo fill="white" click={props.menuToggleClickHandler} />
      </LogoWrapper>
      <PageLinks onClick={props.menuToggleClickHandler}>
        <Link to="/">Home</Link>
        {links.map((link, i) => {
          if (link.text) return <Link to={link.url} key={i}>{link.text}</Link>
        })}
      </PageLinks>
    </Container>
  )
}
