import React from 'react';

import {Link} from '../utils';
import styled from 'styled-components';

const Lockup = styled.div`
  position: relative;
  transition: opacity 0.5s ease;
  opacity: ${props => props.lockup ? '0' : '1'};
`;

const BrandmarkStyled = styled.div`
  height: 30px;
  width: 30px;
  position: relative;

  div {
    height: 25px;
    width: 25px;
    border: 1px solid #FF6367;
    border-radius: 100px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    transition: all 0.5s ease;

    &:nth-child(2) {
      height: 19px;
      width: 19px;
      transform: translate(-45%,-49%);
    }

    &:nth-child(3) {
      height: 18px;
      width: 18px;
      transform: translate(-56%,-56%);
    }
  }

  &:hover {
    div {
      &:nth-child(2) {
        transform: translate(-50%,-50%) scale(1.3);
      }

      &:nth-child(3) {
        transform: translate(-50%,-50%) scale(1.4);
      }
    }
  }

  span {
    height: 10px;
    width: 10px;
    border-radius: 100px;
    background-color: #2456EA;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
`;

export default function Brandmark(props) {
  return (
    <Lockup lockup={props.lockup ? true : false}>
      <Link to='/' onClick={props.click}>
        <BrandmarkStyled>
          <div></div>
          <div></div>
          <div></div>
          <span></span>
        </BrandmarkStyled>
      </Link>
    </Lockup>
  )
}
