import React from 'react'
import styled from '../../../node_modules/styled-components';

import {Link} from '../../utils';
import Hamburger from './Hamburger';

const Container = styled.div`
  position: fixed;
  top: 35px;
  right: 10px; 
  transform: translateY(-120%);
  z-index: 1001;
  text-decoration: none;
  border: none;
  display: flex;
  align-items: center;

  a {
    margin: 0 8px;
    color: #2456EA;
  }

  @media (min-width: 375px) {
    top: 40px;
  }

  @media (min-width: 768px) {
    top: 10vh;
    right: calc((100vw - 650px) / 2);
  }

  @media (min-width: 1024px) {
    right: calc((100vw - 900px) / 2);
  }

  @media (min-width: 1200px) {
    right: calc((100vw - 1000px) / 2);
  }

  @media (min-width: 1400px) {
    right: calc((100vw - 1200px) / 2);
  }
`;

const IconWrapper = styled.div`
  display: none;

  @media (min-width: 375px) {
    display: initial;
  }
`;



export default function MiniNav(props) {
  return (
    <Container>
      <IconWrapper>
        <Link to="/about">info</Link>
        <Link to="/blog">
        <svg width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clipPath="url(#clip0)">
            <path d="M7.448 12.88H6.552C5.992 12.88 5.488 12.488 5.376 11.928L0.224 10.528C0.112 10.472 0 10.36 0 10.248V1.96C0 1.904 0.056 1.792 0.112 1.736C0.168 1.68 0.224 1.68 0.336 1.68L1.68 1.904V0.28C1.68 0.112 1.792 0 1.96 0C4.648 0 6.272 0.896 7 1.792C7.728 0.896 9.352 0 12.04 0C12.208 0 12.32 0.112 12.32 0.28V1.904L13.664 1.68C13.72 1.68 13.832 1.68 13.888 1.736C13.944 1.792 14 1.904 14 1.96V10.248C14 10.36 13.888 10.472 13.776 10.528L8.624 11.928C8.512 12.488 8.064 12.88 7.448 12.88ZM0.56 10.024L5.712 11.424C5.824 11.48 5.936 11.592 5.936 11.704C5.936 12.04 6.216 12.32 6.552 12.32H7.448C7.784 12.32 8.064 12.04 8.064 11.704C8.064 11.592 8.176 11.48 8.288 11.424L13.44 10.024V2.296L12.32 2.464V9.128C12.32 9.296 12.208 9.408 12.04 9.408C9.408 9.408 8.456 10.08 7.504 10.976C7.168 11.256 6.776 11.256 6.496 10.976C5.544 10.08 4.592 9.408 1.96 9.408C1.792 9.408 1.68 9.296 1.68 9.128V2.464L0.56 2.296V10.024ZM7.28 2.408V10.416C8.232 9.576 9.24 8.904 11.76 8.848V0.56C9.184 0.616 7.784 1.568 7.28 2.408ZM2.24 8.848C4.76 8.904 5.768 9.576 6.72 10.416V2.408C6.216 1.568 4.816 0.616 2.24 0.56V8.848ZM6.104 3.304C5.32 2.968 4.312 2.688 3.192 2.576L3.136 3.136C4.144 3.248 5.096 3.472 5.824 3.808L6.104 3.304ZM6.104 4.872C5.32 4.48 4.312 4.256 3.192 4.144L3.136 4.704C4.2 4.816 5.152 5.04 5.88 5.376L6.104 4.872ZM6.104 6.44C5.32 6.104 4.312 5.824 3.192 5.712L3.136 6.272C4.144 6.384 5.096 6.608 5.824 6.944L6.104 6.44ZM10.864 3.136L10.808 2.576C9.744 2.688 8.68 2.912 7.896 3.304L8.12 3.808C8.848 3.472 9.8 3.248 10.864 3.136ZM10.864 4.704L10.808 4.144C9.744 4.256 8.68 4.48 7.896 4.872L8.12 5.376C8.848 5.04 9.8 4.816 10.864 4.704ZM10.864 6.272L10.808 5.712C9.744 5.824 8.68 6.048 7.896 6.44L8.12 6.944C8.848 6.608 9.8 6.384 10.864 6.272Z" fill="#2456EA"/>
          </g>
          <defs>
            <clipPath id="clip0">
              <rect width="14" height="12.88" fill="white"/>
            </clipPath>
          </defs>
        </svg>
        </Link>
        <Link to="/podcast">
          <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.48 14C10.696 14 10.08 13.384 10.08 12.6V9.24C10.08 8.456 10.696 7.84 11.48 7.84C12.264 7.84 12.88 8.456 12.88 9.24V10.64H12.992C13.216 10.64 13.44 10.36 13.44 10.136V6.944C13.44 5.32 12.824 3.808 11.704 2.632L10.976 3.36C10.696 3.64 10.248 3.64 9.968 3.416C9.128 2.688 8.064 2.296 6.944 2.296C5.824 2.296 4.76 2.688 3.92 3.36C3.64 3.584 3.248 3.528 2.968 3.304L2.296 2.576C1.176 3.752 0.56 5.32 0.56 6.944V10.136C0.56 10.36 0.728 10.64 1.008 10.64H1.12V11.2H1.008C0.448 11.2 0 10.696 0 10.136V6.944C0 5.096 0.728 3.36 2.016 2.016C3.36 0.728 5.096 0 7 0C8.848 0 10.64 0.728 11.984 2.016C13.272 3.36 14 5.096 14 6.944V10.136C14 10.696 13.552 11.2 12.992 11.2H12.88V12.6C12.88 13.384 12.264 14 11.48 14ZM11.48 8.4C11.032 8.4 10.64 8.792 10.64 9.24V12.6C10.64 13.048 11.032 13.44 11.48 13.44C11.928 13.44 12.32 13.048 12.32 12.6V9.24C12.32 8.792 11.928 8.4 11.48 8.4ZM6.944 1.68C8.176 1.68 9.352 2.128 10.304 2.912C10.36 2.968 10.472 2.968 10.584 2.912L11.312 2.184C10.136 1.12 8.624 0.56 7 0.56C5.376 0.56 3.864 1.12 2.688 2.184L3.36 2.856C3.416 2.912 3.528 2.912 3.64 2.856C4.536 2.072 5.712 1.68 6.944 1.68ZM2.52 14C1.736 14 1.12 13.384 1.12 12.6V9.24C1.12 8.456 1.736 7.84 2.52 7.84C3.304 7.84 3.92 8.456 3.92 9.24V12.6C3.92 13.384 3.304 14 2.52 14ZM2.52 8.4C2.072 8.4 1.68 8.792 1.68 9.24V12.6C1.68 13.048 2.072 13.44 2.52 13.44C2.968 13.44 3.36 13.048 3.36 12.6V9.24C3.36 8.792 2.968 8.4 2.52 8.4Z" fill="#2456EA"/>
          </svg>
        </Link>
        <Link to="/contact">
          <svg width="14" height="11" viewBox="0 0 14 11" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0)">
              <path d="M13.328 0H0.672C0.336 0 0 0.336 0 0.672V9.912C0 10.304 0.336 10.64 0.672 10.64H13.272C13.664 10.64 13.944 10.304 13.944 9.968V0.672C14 0.336 13.664 0 13.328 0ZM0.56 9.688V1.512L4.648 5.6L0.56 9.688ZM0.952 10.08L5.04 5.992L6.776 7.728C6.832 7.784 6.888 7.784 7 7.784C7.112 7.784 7.168 7.784 7.224 7.728L8.96 5.992L13.048 10.08H0.952ZM13.44 9.688L9.352 5.6L13.44 1.512V9.688ZM13.44 0.728L7 7.168L0.56 0.728C0.56 0.616 0.616 0.56 0.672 0.56H13.272C13.384 0.56 13.44 0.616 13.44 0.728Z" fill="#2456EA"/>
            </g>
            <defs>
              <clipPath id="clip0">
                <rect width="14" height="10.64" fill="white"/>
              </clipPath>
            </defs>
          </svg>
        </Link>
      </IconWrapper>
      <Hamburger click={props.menuToggleClickHandler} menuOpen={props.menuOpen}/>
    </Container>
  )
}
