import React, {useState} from 'react';
import styled from 'styled-components';
import { useStaticQuery, graphql, Link } from "gatsby"

import MiniNav from './MiniNav';
import SideBar from './SideBar';
import Overlay from './Overlay';
import Brandmark from '../Brandmark'

const Nav = styled.nav`
  border: 1px solid #2456EA;
  position: fixed;
  top: 40px; 
  right: 10px; 
  bottom: 10px; 
  left: 10px;
  z-index: 1000;
  pointer-events: none;

  @media (min-width: 768px) {
    top: 10vh; 
    right: calc((100vw - 650px) / 2); 
    bottom: 10vh; 
    left: calc((100vw - 650px) / 2);
  }

  @media (min-width: 1024px) {
    right: calc((100vw - 900px) / 2); 
    left: calc((100vw - 900px) / 2);
  }

  @media (min-width: 1200px) {
    right: calc((100vw - 1000px) / 2); 
    left: calc((100vw - 1000px) / 2);
  }

  @media (min-width: 1400px) {
    right: calc((100vw - 1200px) / 2); 
    left: calc((100vw - 1200px) / 2);
  }

  * {
    pointer-events: initial;
    border-bottom: none;
  }
`;

const Frame = styled.div`
  outline: 1000px solid white;
  position: fixed;
  top: 40px; 
  right: 10px; 
  bottom: 10px; 
  left: 10px;
  z-index: 1000;
  pointer-events: none;

  @media (min-width: 768px) {
  top: 10vh; 
  right: calc((100vw - 650px) / 2); 
  bottom: 10vh; 
  left: calc((100vw - 650px) / 2);
  }

  @media (min-width: 1024px) {
  right: calc((100vw - 900px) / 2); 
  left: calc((100vw - 900px) / 2);
  }

  @media (min-width: 1200px) {
  right: calc((100vw - 1000px) / 2); 
  left: calc((100vw - 1000px) / 2);
  }

  @media (min-width: 1400px) {
  right: calc((100vw - 1200px) / 2); 
  left: calc((100vw - 1200px) / 2);
  }
`;

const Href = styled(Link)`
  position: fixed;
  top: 35px;
  left: 10px; 
  transform: translateY(-100%);
  z-index: 1001;
  text-decoration: none;
  border: none;
  color: #2456EA;

  @media (min-width: 768px) {
    top: 10vh;
    left: calc((100vw - 650px) / 2);
  }

  @media (min-width: 1024px) {
    left: calc((100vw - 900px) / 2);
  }

  @media (min-width: 1200px) {
    left: calc((100vw - 1000px) / 2);
  }

  @media (min-width: 1400px) {
    left: calc((100vw - 1200px) / 2);
  }
`;

const BrandRings = styled.div`
  position: fixed;
  top: 90px;
  left: 20px; 
  transform: translateY(-100%);
  z-index: 1;

  @media (min-width: 768px) {
    top: calc(10vh + 40px);
    left: calc(((100vw - 650px) / 2) + 10px);
  }

  @media (min-width: 1024px) {
    left: calc(((100vw - 900px) / 2) + 10px);
  }

  @media (min-width: 1200px) {
    left: calc(((100vw - 1000px) / 2) + 10px);
  }

  @media (min-width: 1400px) {
    left: calc(((100vw - 1200px) / 2) + 10px);
  }
`;

const OuterLinks = styled.div`
  a {
    position: fixed;
    z-index: 1001;
    display: none;
    color: #FF6367;

    @media (min-width: 768px) {
      display: initial;
    }

    &:first-child {
      left: calc((100vw - 1000px) / 2);
      top: 50%;
      transform: translateX(-80%) rotate(90deg);

      @media (min-width: 768px) {
        left: calc((100vw - 650px) / 2);
      }

      @media (min-width: 1024px) {
        left: calc((100vw - 900px) / 2);
      }

      @media (min-width: 1200px) {
        left: calc((100vw - 1000px) / 2);
      }

      @media (min-width: 1400px) {
        left: calc((100vw - 1200px) / 2);
      }
    }

    &:nth-child(3) {
      left: 50%;
      bottom: 10vh;
      transform: translate(-50%, 130%);
    }

    &:nth-child(2) {
      right: calc((100vw - 1000px) / 2);
      top: 50%;
      transform: translateX(74%) rotate(-90deg);

      @media (min-width: 768px) {
        right: calc((100vw - 650px) / 2);
      }

      @media (min-width: 1024px) {
        right: calc((100vw - 900px) / 2);
      }

      @media (min-width: 1200px) {
        right: calc((100vw - 1000px) / 2);
      }

      @media (min-width: 1400px) {
        right: calc((100vw - 1200px) / 2);
      }
    }
  }
`;

export default function Header(props) {
  const [menuOpen, setMenuOpen] = useState(false)
  const data = useStaticQuery(graphql`
    {
      siteData {
        data {
          navigation {
            outerlinks {
              bottom {
                text
                url
              }
              left {
                url
                text
              }
              right {
                text
                url
              }
            }
          }
        }
      }
    }
  `);
  const outerlinks = data.siteData.data.navigation.outerlinks

  const menuToggleClickHandler = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <Nav>
      <Overlay 
        menuToggleClickHandler={menuToggleClickHandler}
        menuOpen={menuOpen}
      />
      <SideBar 
        menuToggleClickHandler={menuToggleClickHandler}
        menuOpen={menuOpen}
      />
      <Href to="/">https://wearemntr.co</Href>
      <BrandRings>
        <Brandmark lockup={props.lockup}/>
      </BrandRings>
      <MiniNav 
        menuToggleClickHandler={menuToggleClickHandler}
        menuOpen={menuOpen}
      ></MiniNav>
      <OuterLinks>
        {outerlinks.left.text ? <Link to={outerlinks.left.url}>{ outerlinks.left.text }</Link> : ''}
        {outerlinks.right.text ? <Link to={outerlinks.right.url}>{ outerlinks.right.text }</Link> : ''}
        {outerlinks.bottom.text ? <Link to={outerlinks.bottom.url}>{ outerlinks.bottom.text }</Link> : ''}
      </OuterLinks>
      <Frame />
    </Nav>
  );
}
