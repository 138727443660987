import React from 'react';
import styled from 'styled-components';
import Logo from './Logo';
import _ from 'lodash';
import Brandmark from './Brandmark'

const Hero = styled.section`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  padding: 100px 10px;
  text-align: center;

  @media (min-width: 768px) {
    min-height: 80vh;
  }

  h1 {
    font-size: 18px;
    margin: 20px 0;
    max-width: 500px;

    @media (min-width: 375px) {
      font-size: 24px;
    }

    @media (min-width: 768px) {
      font-size: 34px;
    }
  }

  svg {
    max-width: 150px;
  }
`;

const ArrowBtn = styled.a`
  color: #FF6367;
  display: flex;
  flex-direction: column;
  align-items: center;

  &:hover {
    color: #FF6367;
    opacity: 0.7;
  }

  div {
    width: 30px;
    height: 55px;
    border: 1px solid #FF6367;
    border-radius: 50px;
    position: relative;
    margin-top: 10px;
  }

  span {
    height: 28px;
    width: 1px;
    display: block;
    background-color: #FF6367;
    position: absolute;
    top: 10px;
    left: 50%;
    transform: translateX(-50%);

    &:last-child {
      bottom: 10px;
      top: unset;
      border: 1px solid #FF6367;
      border-top: none;
      border-right: none;
      background: transparent;
      height: 7px;
      width: 7px;
      transform: translateX(-50%) rotate(-45deg);
    }
  }
`;

export default class HeroBlock extends React.Component {
  constructor(props) {
    super(props)
    console.log(props)
  }
  render() {
    const fm = this.props.pageContext.frontmatter
    return (
      <Hero>
        <div style={{ padding: "2rem", transform: "scale(2)" }}>
          <Brandmark/>
        </div>
        <Logo fill="#2456EA" />
        <h1>{fm.hero_content}</h1>
        <ArrowBtn href={fm.hero_url}>
          {fm.hero_label}
          <div>
            <span></span>
            <span></span>
          </div>
        </ArrowBtn>
      </Hero>
    );
  }
}
