import React from 'react';
import styled from 'styled-components';
// import _ from 'lodash';

const Container = styled.section`
  text-align: center;
  padding: 100px 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #FFFF;

  p {
    max-width: 500px;
  }

  svg {
    max-width: 90%;
  }
`;

const Inner = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 40px;
`;

export default class CtaCentered extends React.Component {
  render() {
    return (
      <Container id="hero">
        <Inner>
          <h1>Ready to Get Started?</h1>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
          <a href="https://wearemntr.mn.co" className="button" target="_blank" rel="noopener noreferrer">Join Now</a>
        </Inner>
      </Container>
    );
  }
}
