import React from 'react';
import _ from 'lodash';
import styled from 'styled-components';

import SubscribeForm from './SubscribeForm';

const Container = styled.section`
  text-align: center;
`;

export default class CtaBlock extends React.Component {
    render() {
    const fm = this.props.pageContext ? this.props.pageContext.frontmatter : null
        return (
            <Container id={_.get(this.props, 'section.section_id')} className="block cta-block outer">
              <div className="inner-small">
              {fm && <h2 className="block-title" style={{ marginBottom: '20px' }}>{fm.subscribe_title}</h2>}
                <SubscribeForm />
              </div>
            </Container>
        );
    }
}
