import Benefits from './Benefits';
import ContentBlock from './ContentBlock';
import CtaBlock from './CtaBlock';
import CtaButtons from './CtaButtons';
import CtaCentered from './CtaCentered';
import EpisodeBlock from './EpisodeBlock';
import FaqBlock from './FaqBlock';
import FeaturesBlock from './FeaturesBlock';
import Footer from './Footer';
import Header from './Header/Header';
import HeroBlock from './HeroBlock';
import PostsBlock from './PostsBlock';
import PricingBlock from './PricingBlock';
import ReviewsBlock from './ReviewsBlock';
import Social from './Social';
import SubscribeForm from './SubscribeForm';
import SubscribeBlock from './SubscribeBlock';
import Testimonial from './Testimonial';
import ValueAdd from './ValueAdd';
import Layout from './Layout';
import "../styles/main.css"

export {
    Benefits,
    ContentBlock,
    CtaBlock,
    CtaButtons,
    CtaCentered,
    EpisodeBlock,
    FaqBlock,
    FeaturesBlock,
    Footer,
    Header,
    HeroBlock,
    PostsBlock,
    PricingBlock,
    ReviewsBlock,
    Social,
    SubscribeForm,
    SubscribeBlock,
    Testimonial,
    ValueAdd,
    Layout
};

export default {
    Benefits,
    ContentBlock,
    CtaBlock,
    CtaButtons,
    CtaCentered,
    EpisodeBlock,
    FaqBlock,
    FeaturesBlock,
    Footer,
    Header,
    HeroBlock,
    PostsBlock,
    PricingBlock,
    ReviewsBlock,
    Social,
    SubscribeBlock,
    SubscribeForm,
    Testimonial,
    ValueAdd,
    Layout
};
